import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import QrReader from 'react-qr-reader';

import { withFirebase } from '../Firebase';

const SkanPage = ({ firebase }) => {
  const [result, setResult] = useState('Просканируйте карту.');
  const [validUser, setValidUser] = useState(false);

  const handleScan = async (data) => {
    if (data) {
      const str = data.split('&');
      const producerUID = str[0].split('=')[1];
      const userUID = str[1].split('=')[1];
      const discount = str[2].split('=')[1];

      const currentProducer = await firebase.currentProducer().get();
      const { users = [] } = currentProducer.data();

      if (
        currentProducer.id === producerUID &&
        users.some(({ userUid }) => userUID === userUid)
      ) {
        setResult(`Пользователь найден. Скидка ${discount}%.`);
        setValidUser(true);
        return;
      }

      if (users.some(({ userUid }) => userUID !== userUid)) {
        setResult(`У пользователя нет дисконтной карты.`);
        return;
      }

      setResult('Такой карты не существует.');
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const useStyles = makeStyles(() => ({
    skanner: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '70vh',
    },
    frame: {
      margin: '0 auto',
      maxHeight: '350px',
      maxWidth: '350px',
      width: '100%',
    },
    result: {
      padding: '0.5rem 1rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      fontWeight: 'bold',
      fontSize: '1.5rem',
      textAlign: 'center',
    },
    resultBtn: {
      cursor: 'pointer',
      width: '3rem',
      border: 'none',
      backgroundColor: 'white',
      borderRadius: '0.5rem',
    },
    resultContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.skanner}>
      {result === 'Просканируйте карту.' ? (
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          className={classes.frame}
        />
      ) : (
        <div className={classes.resultContainer}>
          <p className={classes.result}>{result}</p>
          <Button
            color="primary"
            variant="contained"
            disabled={!validUser}
          >
            Выдать скидку
          </Button>
        </div>
      )}
    </div>
  );
};

export default withFirebase(SkanPage);
