import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';

const HomePage = () => (
  <>
    <h1>Домашняя страница</h1>
    <p>
      Домашняя страница доступна только авторизованному пользователю.
    </p>
  </>
);

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(HomePage);
