import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import QRCode from 'qrcode.react';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  },
});

const CodeDialog = withStyles(styles)(
  ({
    isOpen,
    setOpenedKey,
    producerUid,
    producerName,
    discount,
    classes,
  }) => {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <Dialog
            onClose={() => setOpenedKey('')}
            aria-labelledby="simple-dialog-title"
            open={isOpen}
          >
            <DialogTitle id="simple-dialog-title">
              Карта предприятия
              <strong> {producerName}</strong>:
            </DialogTitle>
            <DialogContent className={classes.root}>
              <QRCode
                size={200}
                value={`producerUID=${producerUid}&userUID=${authUser.uid}&discount=${discount}`}
              />
            </DialogContent>
          </Dialog>
        )}
      </AuthUserContext.Consumer>
    );
  },
);

export default withFirebase(CodeDialog);
