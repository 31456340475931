export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PROD_LIST = '/prodlist';
export const ADMIN = '/admin';
export const CARDS = '/cards';
export const SKAN = '/skan';
export const PRODUCER_USERS = '/prod-users';
