import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
  }

  doAddDiscountCard = async (
    producerName,
    producerUid,
    details,
    discount,
    email,
  ) => {
    const currentUser = await this.currentUser().get();
    const { cards = [], username } = currentUser.data();
    const producer = await this.producer(producerUid).get();
    const { users = [] } = producer.data();
    const userUid = this.auth.currentUser.uid;

    if (users.some((user) => user.userUid.indexOf(userUid) !== -1)) {
      return;
    }

    this.producer(producerUid).update({
      users: [...users, { userUid, username }],
    });

    this.currentUser().update({
      cards: [
        ...cards,
        { producerUid, producerName, details, discount, email },
      ],
    });
  };

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => this.auth.signOut();

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
        this.producer(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbProducer = snapshot.data();

            // merge auth and db producer
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              providerData: authUser.providerData,
              ...dbProducer,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = (uid) => this.db.doc(`users/${uid}`);

  currentUser = () =>
    this.db.doc(`users/${this.auth.currentUser.uid}`);

  users = () => this.db.collection('users');

  // *** Producer API ***

  producer = (uid) => this.db.doc(`producers/${uid}`);

  currentProducer = () =>
    this.db.doc(`producers/${this.auth.currentUser.uid}`);

  producers = () => this.db.collection('producers');

  // *** Message API ***

  message = (uid) => this.db.doc(`messages/${uid}`);

  messages = () => this.db.collection('messages');
}

export default Firebase;
