import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignUpPage = () => (
  <>
    <h1>Зарегистрироваться</h1>
    <SignUpForm />
  </>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  details: '',
  discount: '',
  isAdmin: false,
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS =
  'Аккаунт с такой почтой уже существует.';

const SignUpFormBase = ({ firebase, history }) => {
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmit = (event) => {
    const {
      username,
      email,
      passwordOne,
      isAdmin,
      details,
      discount,
    } = state;

    if (isAdmin) {
      firebase
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .then((authUser) => {
          // Create a producer in your Firebase realtime database
          return firebase.producer(authUser.user.uid).set(
            {
              username,
              email,
              details,
              discount,
            },
            { merge: true },
          );
        })
        .then(() => {
          setState({ ...INITIAL_STATE });
          history.push(ROUTES.HOME);
        })
        .catch((error) => {
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
          }

          setState({ error });
        });
    }

    if (!isAdmin) {
      firebase
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .then((authUser) => {
          // Create a user in your Firebase realtime database
          return firebase.user(authUser.user.uid).set(
            {
              username,
              email,
            },
            { merge: true },
          );
        })
        .then(() => {
          setState({ ...INITIAL_STATE });
          history.push(ROUTES.HOME);
        })
        .catch((error) => {
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
          }

          setState({ error });
        });
    }

    event.preventDefault();
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const {
    username,
    email,
    passwordOne,
    passwordTwo,
    isAdmin,
    error,
    details,
    discount,
  } = state;

  const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === '' ||
    email === '' ||
    username === '';

  return (
    <form onSubmit={onSubmit}>
      <TextField
        fullWidth
        name="username"
        value={username}
        onChange={onChange}
        type="text"
        placeholder="Ф.И.О. или название предприятия"
      />
      <TextField
        fullWidth
        name="email"
        value={email}
        onChange={onChange}
        type="text"
        placeholder="Почта"
      />
      <TextField
        fullWidth
        name="passwordOne"
        value={passwordOne}
        onChange={onChange}
        type="password"
        placeholder="Пароль"
      />
      <TextField
        fullWidth
        name="passwordTwo"
        value={passwordTwo}
        onChange={onChange}
        type="password"
        placeholder="Подтвердите пароль"
      />
      <InputLabel id="role">Выберите статус</InputLabel>
      <Select
        native
        value={isAdmin}
        onChange={onChange}
        inputProps={{
          name: 'isAdmin',
          id: 'role',
        }}
      >
        <option value={false}>Клиент</option>
        <option value={true}>Предприятие</option>
      </Select>
      {isAdmin && (
        <TextField
          fullWidth
          name="details"
          value={details}
          onChange={onChange}
          type="text"
          placeholder="Введите описание предприятия"
        />
      )}
      {isAdmin && (
        <TextField
          fullWidth
          name="discount"
          value={discount}
          onChange={onChange}
          type="text"
          placeholder="Введите размер скидки для клиентов"
        />
      )}
      <Button
        fullWidth
        color="primary"
        disabled={isInvalid}
        type="submit"
      >
        Зарегистрироваться
      </Button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignUpLink = () => (
  <p>
    У вас нет аккаунта?{' '}
    <Link to={ROUTES.SIGN_UP}>Зарегистрируйтесь</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
