import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { withFirebase } from '../Firebase';

const ProducerPage = ({ firebase }) => {
  const [loading, setLoading] = useState(false);
  const [producers, setProducers] = useState([]);

  useEffect(() => {
    setLoading(true);

    const unsubscribe = firebase
      .producers()
      .onSnapshot(async (snapshot) => {
        let producers = [];
        const currentUser = await firebase.currentUser().get();
        const { cards = [] } = currentUser.data();

        snapshot.forEach((doc) => {
          if (
            !cards.some(({ producerUid }) => doc.id === producerUid)
          ) {
            producers.push({ ...doc.data(), uid: doc.id });
          }
        });

        setProducers(producers);
      });

    setLoading(false);

    return unsubscribe;
  }, [firebase]);

  const onHandleClick = (username, uid, details, discount, email) => {
    firebase.doAddDiscountCard(
      username,
      uid,
      details,
      discount,
      email,
    );
  };

  return (
    <>
      {loading && <div>Загрузка ...</div>}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Название</TableCell>
              <TableCell align="right">Описание</TableCell>
              <TableCell align="right">Размер скидки</TableCell>
              <TableCell align="right">Почта</TableCell>
              <TableCell align="right">
                Получить дисконтную карту
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {producers.map(
              ({ uid, username, details, email, discount }) => (
                <TableRow key={uid}>
                  <TableCell align="right">{username}</TableCell>
                  <TableCell align="right">{details}</TableCell>
                  <TableCell align="right">{discount}%</TableCell>
                  <TableCell align="right">{email}</TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        onHandleClick(
                          username,
                          uid,
                          details,
                          discount,
                          email,
                        )
                      }
                    >
                      Получить
                    </Button>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withFirebase(ProducerPage);
