import React from 'react';
import ProducerPage from '../ProducerPage';

const ProdList = () => (
  <div>
    <h1>Предприятия</h1>
    <p>
      На этой странице можно просмотреть все зарегистрированные
      предприятия
    </p>

    <ProducerPage />
  </div>
);

export default ProdList;
