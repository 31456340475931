import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import HomePage from '../Home';
import ProdList from '../ProdList';
import Cards from '../Cards';
import SkanPage from '../SkanPage';
import ProducerUsers from '../ProducerUsers';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const App = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      margin: 'auto',
      maxWidth: '600px',
      marginTop: '3rem',
    },
  }));
  const classes = useStyles();

  return (
    <Router>
      <>
        <Navigation />
        <Paper variant="outlined" className={classes.paper}>
          <Grid className={classes.root}>
            <Grid item>
              <Route
                exact
                path={ROUTES.LANDING}
                component={LandingPage}
              />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route path={ROUTES.HOME} component={HomePage} />
              <Route path={ROUTES.PROD_LIST} component={ProdList} />
              <Route path={ROUTES.CARDS} component={Cards} />
              <Route path={ROUTES.SKAN} component={SkanPage} />
              <Route
                path={ROUTES.PRODUCER_USERS}
                component={ProducerUsers}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    </Router>
  );
};

export default withAuthentication(App);
