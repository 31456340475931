import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { SignUpLink } from '../SignUp';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <>
    <h1>Войти</h1>
    <SignInForm />
    <SignUpLink />
  </>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    display: 'block',
  },
  button: {
    marginTop: '0.5rem',
  },
}));

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `Аккаунт с такой почтой уже существует.`;

const SignInFormBase = ({ firebase, history }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const { email, password, error } = state;

  const onSubmit = (event) => {
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setState({ ...INITIAL_STATE });
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        setState({ error });
      });

    event.preventDefault();
  };

  const onChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value });
  };

  const classes = useStyles();

  const isInvalid = password === '' || email === '';

  return (
    <form onSubmit={onSubmit}>
      <TextField
        name="email"
        value={email}
        onChange={onChange}
        type="text"
        placeholder="Почта"
        className={classes.fullWidth}
      />
      <TextField
        name="password"
        value={password}
        onChange={onChange}
        type="password"
        placeholder="Пароль"
        className={classes.fullWidth}
      />
      <Button
        size="large"
        color="primary"
        disabled={isInvalid}
        type="submit"
        className={classes.button}
      >
        Войти
      </Button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignInGoogleBase = ({ firebase, history }) => {
  const [state, setState] = useState({ error: null });

  const onSubmit = (event) => {
    firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
          },
          { merge: true },
        );
      })
      .then(() => {
        setState({ error: null });
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        setState({ error });
      });

    event.preventDefault();
  };

  const { error } = state;

  return (
    <form onSubmit={onSubmit}>
      <Button type="submit">Войти через Google</Button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignInFacebookBase = ({ firebase, history }) => {
  const [state, setState] = useState({ error: null });

  const onSubmit = (event) => {
    firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
          },
          { merge: true },
        );
      })
      .then(() => {
        setState({ error: null });
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        setState({ error });
      });

    event.preventDefault();
  };

  const { error } = state;

  return (
    <form onSubmit={onSubmit}>
      <Button type="submit">Войти через Facebook</Button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook };
