import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import FaceIcon from '@material-ui/icons/Face';
import CardIcon from '@material-ui/icons/CreditCard';
import HomeIcon from '@material-ui/icons/Home';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

const Navigation = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsOpen(!isOpen);
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <div className={classes.root}>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer()}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer open={isOpen} onClose={toggleDrawer()}>
                  {<NavigationAuth authUser={authUser} />}
                </Drawer>
              </Toolbar>
            </AppBar>
          </div>
        ) : (
          <div className={classes.root}>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer()}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer open={isOpen} onClose={toggleDrawer()}>
                  {<NavigationNonAuth />}
                </Drawer>
              </Toolbar>
            </AppBar>
          </div>
        )
      }
    </AuthUserContext.Consumer>
  );
};

const NavigationAuth = ({ authUser }) => (
  <>
    <List>
      <ListItem button>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <Link to={ROUTES.LANDING}>Главная страница</Link>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <Link to={ROUTES.HOME}>Домашняя страница</Link>
      </ListItem>
      {!!authUser.discount && (
        <ListItem button>
          <ListItemIcon>
            <FaceIcon />
          </ListItemIcon>
          <Link to={ROUTES.PRODUCER_USERS}>Ваши пользователи</Link>
        </ListItem>
      )}
      {!!authUser.discount && (
        <ListItem button>
          <ListItemIcon>
            <CardIcon />
          </ListItemIcon>
          <Link to={ROUTES.SKAN}>Сканировать карту</Link>
        </ListItem>
      )}
      {!authUser.discount && (
        <ListItem button>
          <ListItemIcon>
            <FaceIcon />
          </ListItemIcon>
          <Link to={ROUTES.PROD_LIST}>Предприятия</Link>
        </ListItem>
      )}
      {!authUser.discount && (
        <ListItem button>
          <ListItemIcon>
            <CardIcon />
          </ListItemIcon>
          <Link to={ROUTES.CARDS}>Ваши карты</Link>
        </ListItem>
      )}
      <ListItem button>
        <ListItemIcon>
          <ArrowLeftIcon />
        </ListItemIcon>
        <Link to={ROUTES.LANDING}>
          <SignOutButton />
        </Link>
      </ListItem>
    </List>
  </>
);

const NavigationNonAuth = () => (
  <List>
    <ListItem button>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <Link to={ROUTES.LANDING}>Главная страница</Link>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <ArrowRightIcon />
      </ListItemIcon>
      <Link to={ROUTES.SIGN_UP}>Зарегистрироваться</Link>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <ArrowRightIcon />
      </ListItemIcon>
      <Link to={ROUTES.SIGN_IN}>Войти</Link>
    </ListItem>
  </List>
);

export default Navigation;
