import React from 'react';
import Button from '@material-ui/core/Button';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <Button color="primary" type="button" onClick={firebase.doSignOut}>
    Выйти
  </Button>
);

export default withFirebase(SignOutButton);
