import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { withFirebase } from '../Firebase';

import CodeDialog from '../CodeDialog';

const Cards = ({ firebase }) => {
  const [loading, setLoading] = useState(false);
  const [userCards, setCards] = useState([]);
  const [openedUid, setOpenedUid] = useState('');

  useEffect(() => {
    setLoading(true);

    const unsubscribe = firebase.onAuthUserListener(
      async (authUser) => {
        const currentUser = await firebase.user(authUser.uid).get();
        const { cards = [] } = currentUser.data();

        setCards(cards);
      },
      () => {
        return;
      },
    );

    setLoading(false);
    return unsubscribe;
  }, [firebase, loading]);

  return (
    <>
      {loading && <div>Загрузка ...</div>}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell>Описание</TableCell>
              <TableCell>Размер скидки</TableCell>
              <TableCell>Почта</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userCards.length ? (
              userCards.map(
                ({
                  producerUid,
                  producerName,
                  details,
                  email,
                  discount,
                }) => (
                  <TableRow key={producerUid}>
                    <TableCell>{producerName}</TableCell>
                    <TableCell>{details}</TableCell>
                    <TableCell>{discount}%</TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        onClick={() => setOpenedUid(producerUid)}
                      >
                        Посмотреть карту
                      </Button>
                      <CodeDialog
                        isOpen={openedUid === producerUid}
                        setOpenedKey={setOpenedUid}
                        producerUid={producerUid}
                        producerName={producerName}
                        discount={discount}
                      />
                    </TableCell>
                  </TableRow>
                ),
              )
            ) : (
              <TableRow>
                <TableCell>У Вас пока нет карт</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withFirebase(Cards);
