import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { withFirebase } from '../Firebase';

const ProducerUsers = ({ firebase }) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setLoading(true);

    const unsubscribe = firebase.onAuthUserListener(
      async (authUser) => {
        const currentProducer = await firebase
          .producer(authUser.uid)
          .get();
        const { users = [] } = currentProducer.data();

        setUsers(users);
      },
      () => {
        return;
      },
    );

    setLoading(false);
    return unsubscribe;
  }, [firebase, loading, users]);

  return (
    <>
      {loading && <div>Загрузка ...</div>}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Имя пользователя</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length ? (
              users.map(({ usererUid, username }) => (
                <TableRow key={usererUid}>
                  <TableCell>{username}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>У Вас пока нет пользователей</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withFirebase(ProducerUsers);
